import React, {Component} from "react"
import styled, {css} from 'styled-components'
import {graphql, Link, StaticQuery} from 'gatsby'
import {media} from "utils/Media"
import {GatsbyImage, StaticImage} from "gatsby-plugin-image";

const Wrapper = styled.div`
    position: relative;
    
    @media ${media.sm} {
    }
    
    .content {
        position: absolute;
        bottom: 20px;
        left: 0;
        width: 100%;
        text-align: center;
    }
    
    h1 {
        margin: 0;
        font-family: ${props => props.theme.font.family.special};
        color: ${props => props.theme.colors.white};
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        text-align: center; 
        text-transform: uppercase;  
        font-size: 2rem;
        
        @media ${media.sm} {
            font-size: 1.5rem;
        }
        
        @media ${media.md} {    
            font-size: 2rem;
        }  
   
    }
    
    .logo {
        max-width: 485px;
    }
    
`

const Query = (props) => (
    <StaticQuery
        query={graphql`
            query {
                FallbackXs: file(relativePath: { eq: "header-edu-home-576.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
                
                FallbackSm: file(relativePath: { eq: "header-edu-home-991.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
              
                FallbackXl: file(relativePath: { eq: "header-edu-home-1920.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                GalleryPageXs: file(relativePath: { eq: "VideoHub/header-sub-GALLERY-576.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
                
                GalleryPageSm: file(relativePath: { eq: "VideoHub/header-sub-GALLERY-991.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
              
                GalleryPageXl: file(relativePath: { eq: "VideoHub/header-sub-GALLERY-1920.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                logo: file(relativePath: { eq: "Logo_lrg.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 800, quality: 100)
                    }
                }
                hyggeMd: file(relativePath: { eq: "hygge/Hygge-Hero-991x360.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920)
                    }
                }
                hyggeSm: file(relativePath: { eq: "hygge/Hygge-Hero-576x360.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920)
                    }
                }
                hyggeXl: file(relativePath: { eq: "hygge/Hygge-Hero-1920x430.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920)
                    }
                }
            }
		`}
        render={data => (
            <TopBar logo={props.logo} pageName={props.pageName} title={props.title} data={data}/>
        )}
    />
)

class TopBar extends Component {
    render() {
        console.log(this.props.data)
        return (
            <Wrapper>
                {this.props.pageName === 'hygge' ? (
                    <React.Fragment>
                    <GatsbyImage image={this.props.data.hyggeSm.childImageSharp.gatsbyImageData} alt=""
                        title=""
                        className="img-fluid d-block d-sm-none" />
                    <GatsbyImage image={this.props.data.hyggeMd.childImageSharp.gatsbyImageData} alt=""
                        title=""
                        className="img-fluid d-none d-sm-block d-lg-none" />
                    <GatsbyImage image={this.props.data.hyggeXl.childImageSharp.gatsbyImageData} alt=""
                        title=""
                        className="img-fluid d-none d-lg-block" />
                    {/* <StaticImage src="../../../images/HomePage/gallery/Hygge-Hero-1920x430.jpg" alt=""
                        title=""
                        className="img-fluid d-none d-lg-block" /> */}
                </React.Fragment>
                ) : (

<>
{this.props.pageName === 'gallery' ?
                    <React.Fragment>
                        <GatsbyImage image={this.props.data.GalleryPageXs.childImageSharp.gatsbyImageData}
                            alt=""
                            title=""
                            className="img-fluid d-block d-sm-none"
                        />
                        <GatsbyImage image={this.props.data.GalleryPageSm.childImageSharp.gatsbyImageData}
                            alt=""
                            title=""
                            className="img-fluid d-none d-sm-block d-lg-none"
                        />
                        <GatsbyImage image={this.props.data.GalleryPageXl.childImageSharp.gatsbyImageData}
                            alt=""
                            title=""
                            className="img-fluid d-none d-lg-block"
                        />
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <GatsbyImage image={this.props.data.FallbackXs.childImageSharp.gatsbyImageData}
                            alt=""
                            title=""
                            className="img-fluid d-block d-sm-none"
                        />
                        <GatsbyImage image={this.props.data.FallbackSm.childImageSharp.gatsbyImageData}
                            alt=""
                            title=""
                            className="img-fluid d-none d-sm-block d-lg-none"
                        />
                        <GatsbyImage image={this.props.data.FallbackXl.childImageSharp.gatsbyImageData}
                            alt=""
                            title=""
                            className="img-fluid d-none d-lg-block"
                        />
                    </React.Fragment>
                }
</>
                ) }
          
               

                <div className="content">
                    {this.props.logo &&
                    <GatsbyImage image={this.props.data.logo.childImageSharp.gatsbyImageData} alt="Frozen the Musical"
                         title="Frozen the Musical"
                         className="img-fluid logo mx-auto"/>
                    }
                    <h1 className={`text-center ${this.props.logo && 'font--smaller'}`}>{this.props.title}</h1>
                </div>
            </Wrapper>
        );
    }
}

export default Query;