import React, {Component} from 'react'
import {Container} from 'reactstrap'
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import styled from "styled-components"
import closeIcon from 'images/close.svg'
import selector from 'images/CastAndCreativePage/cc-selector.svg'
import {media} from "utils/Media"

const Content = styled(Container)`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    position: relative;
    z-index: 4;
    
    h4 {
        line-height: 120%;
        color: ${props => props.theme.colors.navy};
        margin-bottom: 0;
        
        @media ${media.md} {
            margin-bottom: 10px;
        }
        
        @media ${media.md} {
            font-size: 1.75rem;
        }
        
        @media ${media.xl} {
            font-size: 2rem;
        }
    }
    
    h5 {
        line-height: 120%;
        font-family: ${props => props.theme.font.family.base};
        font-weight: normal;
        
        @media ${media.md} {
            font-size: 1.125rem;
        }
        
        @media ${media.xl} {
            font-size: 1.5rem;
        }
    }

    .c-collapse__item {
        width: 100%;
        padding-bottom: 0;
        border-top: 1px solid ${props => props.theme.colors.turquoise2};
        
        @media ${media.sm} {
            border: none;
            width: 280px;
        }
                
        @media ${media.lg} {
            width: 320px;
        }
        
        @media ${media.xl} {
            width: 400px;
        }
        
        &:last-of-type {
            border-bottom: 1px solid ${props => props.theme.colors.turquoise2};
            
            @media ${media.sm} {
                border: none;
            }
        }

        &-trigger {
            cursor: pointer;
            padding-bottom: 1rem / 2;
            position: relative;

            &-img {
                max-width: 280px;
                margin: 0 auto;

                @media ${media.lg} {
                    width: 100%;
                }

                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    opacity: 0;
                    transition: all 0.2s ease-in;
                }

                &:hover {
                    cursor: pointer;

                    &:after {
                        opacity: .5;
                    }

                    &:before {
                        opacity: 1;
                    }
                }
            }

            &-title {
                padding: 5px 10px;
                min-height: 60px;
                display: flex;
                align-items: center;
               
               .helper {
                  width: 100%;
                  text-align: left;
                  
                  @media ${media.sm} {
                      text-align: center;
                  }
               }
                
                @media ${media.sm} {
                    padding: 1rem 1rem 0;
                    text-align: center;
                }

                span {
                    transition: opacity .4s ease;
                    opacity: 1;
                    display: block;
                    margin-bottom: 1rem / 3;

                    &.role {
                        margin-bottom: 0;
                    }
                }
              
            }

            .casts-wrapper {
                display: flex;
                
                 @media ${media.sm} {
                      padding: 10px;
                      display: block;
                 }
                 
                 .casts-img-wrapper {
                      width: 80px;
                      
                      @media ${media.sm} {
                          width: initial;
                      }
                 }
            }
        }

        &-content {
            height: 0;
            visibility: hidden;
            overflow: hidden;
            position: absolute;
            left: 0;
            width: 100%;
            flex-wrap: wrap;
            justify-content: center;
            transition: height .3s ease;

            &-inner {
                padding: 1rem;
                text-align: center;
                max-width: 1360px;
                margin: 0 auto;
                border-top: 1px solid ${props => props.theme.colors.turquoise2};
                
                @media ${media.sm} {
                    background: linear-gradient(180deg, rgba(138, 236, 245, 0) 0%, rgba(138, 236, 245, 0.5) 11.46%, rgba(226, 248, 252, 0) 100%);
                    border: none;
                }
                
                h2 {
                    padding-top: 1rem;
                    margin-bottom: 0;
                }

                p {
                    margin: 0 auto 1.5rem;
                }

                .embed-responsive {
                    max-width: 800px;
                    margin: 0 auto;
                }
            }
        }

        // Active state
        &.active {

            .c-collapse__item-trigger {

                &-img {
                    opacity: 1;
                }
            }

            .c-collapse__item-content {
                visibility: visible;
            }

            .open-icon {
                display: block;
            }

            .close-icon {
                display: none;
            }
        }

        &:hover {
            .c-collapse__item-trigger {
                &-img {
                    opacity: 1;
                }

                &-title {
                    &:after,
                    &:before {
                        opacity: 1;
                    }
                }
            }
        }
    }
`

const CollapseContent = styled.div`
    position: relative;
    z-index: 4;
`

const CloudsBgImage = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    
    .gatsby-image-wrapper {
        max-height: 100% !important;
    }
`

class CustomCollapse extends Component {

    constructor(props) {
        super(props)
        this.state = {
            activeId: "",
            contentHeight: 0
        }

        this.clickHandler = this.clickHandler.bind(this);
    }

    clickHandler(e, id, type, bio) {

        if (type === "production")
            return true;
        
        if (bio === "")
            return true;

        if (this.state.activeId === id) {
            this.setState({
                activeId: "",
                contentHeight: 0
            });
        } else {
            const trigger = e.currentTarget;
            const container = trigger.parentElement;
            const content = trigger.nextSibling;
            const inner = content.children[0];
            const height = inner.offsetHeight;
            this.setState({
                activeId: id,
                contentHeight: height
            });

            const collapseItem = document.getElementById(`collapse${id}${type}`)
        }
    }

    render() {
        const items = this.props.members

        const collapseItems = items.map((item, i) => {
            return <CustomCollapseItem
                key={i}
                id={i}
                item={item}
                data={this.props.data}
                clickHandler={this.clickHandler}
                type={this.props.type}
                activeId={this.state.activeId}
                contentHeight={this.state.activeId === i ? this.state.contentHeight : 0}
                activeClassName={this.state.activeId === i ? 'active' : ''}
            />
        });

        return (
            <Content fluid className="c-collapse">
                {collapseItems}
            </Content>
        )
    }
}

export default CustomCollapse

class CustomCollapseItem extends Component {
    render() {
        const element = this.props.item.node;
        console.log(element.title)
        let image = ""

        if (typeof element.image !== 'undefined') {
            image = <GatsbyImage image={element.image?.childImageSharp.gatsbyImageData} alt={element.title}
                         className="cast-img c-collapse__item-trigger-img"/>
        }

        return (
            <div
                className={`c-collapse__item ${this.props.activeClassName}`}
                style={{marginBottom: this.props.contentHeight + "px"}}
                id={`collapse${this.props.id}${this.props.type}`}>

                <div tabIndex={this.props.id} role="button" aria-pressed="false"
                     className="c-collapse__item-trigger"
                     onClick={(e) => this.props.clickHandler(e, this.props.id, this.props.type, element.bio)}
                     onKeyDown={(e) => this.props.clickHandler(e, this.props.id, this.props.type, element.bio)}
                >
                    <Container fluid={true} className="no-padding-xs">
                        <Container>
                            <div className="casts-wrapper">
                                {this.props.type !== "production" &&
                                  <div className="casts-img-wrapper">
                                      {image}
                                  </div>
                                }

                                <div className="c-collapse__item-trigger-title">
                                    <div className="helper">
                                        <h4 className="">{element.title}</h4>
                                        <h5 className="">{element.role}</h5>
                                    </div>
                                </div>
                            </div>
                            {this.props.activeClassName &&
                            <img src={selector} className="img-fluid mx-auto selector d-none d-sm-block" alt="" title=""/>
                            }
                        </Container>
                    </Container>
                </div>
                {element.bio !== "" &&
                <div
                    className="c-collapse__item-content"
                    style={{height: this.props.contentHeight + "px"}}
                    id={`collapseContent${this.props.id}${this.props.type}`}
                >

                    <div className="c-collapse__item-content-inner">
                        <CollapseContent>
                            <Container>
                                <h4 className="mb-4">{element.title}</h4>
                                <div className="c-collapse__item-content-inner-bg"
                                     dangerouslySetInnerHTML={{__html: element.bio}}/>
                                <img onClick={(e) => this.props.clickHandler(e, this.props.id, this.props.type)}
                                     className="close-icon d-block mx-auto mt-4 pb-4" src={closeIcon} alt="close"/>
                            </Container>
                        </CollapseContent>

                    </div>
                </div>
                }
            </div>
        )
    }
}
