import React, {Component} from "react"
import styled from "styled-components"
import classnames from 'classnames';
import {
    Container,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink
} from 'reactstrap';
import {graphql, StaticQuery} from 'gatsby'
import Layout from "components/Layout/Layout"
import VideoModal from "components/shared/VideoModal"
import CustomCollapse from "components/CastAndCreativePage/CustomCollapse"


import {media} from "utils/Media"
import LazyLoad from "react-lazyload";
import selector from 'images/CastAndCreativePage/selector.jpg'
import FindOutMore from "components/shared/FindOutMore"
import TopBar from "../components/shared/TopBar";

const Content = styled.div`
    margin-bottom: 7rem;
    
    @media ${media.md} {
        margin-bottom: 5rem;
    } 
    
    @media ${media.lg} {
        margin-bottom: 0;
    } 

hr {
    border-top: 1px solid ${props => props.theme.colors.turquoise2};
    margin-bottom: 3rem;
    position: relative;
    z-index: 1;
    opacity: 0;
    
    @media ${media.sm} {
        opacity: 1;
    }
}
.c-collapse-nav.nav {
    justify-content: center;
    width: 100%;
    text-align: center;
    position: relative;
    transform: none;
    bottom: initial;
    left: initial;
    top: initial;
    background: none;
    z-index: 2;
    border: none;
    display: flex;
    flex-wrap: wrap;
    
    &--scale-bg {
        transform: scaleX(-1);
    }

    li {
        position: relative;
        width: 100%;
        &.active {
            padding-bottom: 30px;
        }
    
        @media ${media.sm} {
            width: initial;
            &.active {
                padding: 0;
            }
        }
        a {
            cursor: pointer;
            font-family: ${props => props.theme.font.family.special};
            color: ${props => props.theme.colors.turquoise2};
            font-weight: normal;
            margin: 0;
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            text-align: center;   
            background: none;
            border: none;
            font-size: 2rem;
            
            @media ${media.md} {
                font-size: 3rem;
            }
        
            &:hover, &.active {
                   text-decoration: none;
                   color: ${props => props.theme.colors.navy};
            }
        }
        
        &.active {
            &:after {
                content: "";
                display: block;
                position: absolute;
                bottom: 5px;
                left: 50%;
                transform: translateX(-50%);
                background: url("${selector}") no-repeat center center;
                background-size: 100%;
                width: 114px;
                height: 26px;
    
                @media ${media.sm} {
                    bottom: -20px;
                }
            }
        }

        &:nth-child(1) {
            // width: 50%;

            &:hover ~ .c-collapse-nav.nav--bg, .active ~ .c-collapse-nav.nav--bg {
                opacity: 0;
            }

            &:hover ~ .c-collapse-nav.nav--scale-bg {
                opacity: 1;
            }
        }
    }
}
`

const Query = () => (
    <StaticQuery
        query={graphql`
            query {
                allNewCastJson {
                    edges {
                        node {
                            id
                            title
                            role
                            bio
                            image{
                                childImageSharp {
                                    gatsbyImageData(width: 283, quality: 100)
                                }
                            }
                        }
                    }
                }
                allCreativeJson {
                    edges {
                        node {
                           id
                            title
                            role
                            bio
                            image{
                                childImageSharp {
                                    gatsbyImageData(width: 283, quality: 100)
                                }
                            }
                        }
                    }
                }
                allProductionJson {
                    edges {
                        node {
                           id
                            title
                            role
                        }
                    }
                }
                allOrchestraJson {
                    edges {
                        node {
                           id
                            title
                            role
                        }
                    }
                }
                HeaderImageXs: file(relativePath: { eq: "CastAndCreativePage/Frozen_JUL20_Full-Cast-Grid_576x817_AW.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
                
                HeaderImageSm: file(relativePath: { eq: "CastAndCreativePage/Frozen_JUL20_Full-Cast-Grid_991x945_AW.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                HeaderImageXl: file(relativePath: { eq: "CastAndCreativePage/Frozen_JUL20_Full-Cast-Grid_1159x1920_AW.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                
                videoPlaceholder: file(relativePath: { eq: "CastAndCreativePage/cast-video-holder.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1198, quality: 100)
                    }
                }
                FallbackXs: file(relativePath: { eq: "bg-header-fallback-576.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
                
                FallbackSm: file(relativePath: { eq: "bg-header-fallback-991.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                FallbackLg: file(relativePath: { eq: "bg-header-fallback-1200.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1200, quality: 100)
                    }
                }
                
                FallbackXl: file(relativePath: { eq: "bg-header-fallback-1920.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                
                ForegroundBottom: file(relativePath: { eq: "foreground-bottom.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
            }`}
        render={data => (
            <CastCreativePage data={data}/>
        )}
    />
)

class CastCreativePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeId: "",
            contentHeight: 0,
            activeTab: '1',
            modalOpen: false,
            // src: "https://cdnapisec.kaltura.com/p/1068292/sp/106829200/embedIframeJs/uiconf_id/40058201/partner_id/1068292?iframeembed=true&playerId=kaltura_player&entry_id=1_54agprxu&flashvars&#91;localizationCode&#93;=en&&wid=1_btrvbqh7",
        }
    }

    toggle = (tab, e) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    clickHandler = (e, id) => {
        if (this.state.activeId === id) {
            this.setState({
                activeId: "",
                contentHeight: 0
            });
        } else {
            const trigger = e.currentTarget;
            const content = trigger.nextSibling;
            const inner = content.children[0];
            const height = inner.offsetHeight;

            this.setState({
                activeId: id,
                contentHeight: height
            });
        }

    };
    
    toggleModal = (e, title, src) => {
        e.preventDefault();
        
        this.setState({
            src: src,
            title: title,
            modalOpen: !this.state.modalOpen
        });
    }

    render() {
        return (
            <Layout pageName="cast-and-creative-page" slug="cast-and-creative" title="Cast and Creative" description="Meet the cast and creative team behind Disney’s Frozen the Musical at London’s Theatre Royal Drury Lane from August 2021">
                <TopBar title="Cast & Creative"/>
                <Content>
                    <Container fluid={true}>
                        <Container>
                                <Nav tabs className="c-collapse-nav pt-5">
                                    <NavItem
                                        className={classnames({active: this.state.activeTab === '1'})}
                                    >
                                        <NavLink
                                            className={classnames({active: this.state.activeTab === '1'})}
                                            onClick={(e) => {
                                                this.toggle('1', e);
                                            }}
                                        >
                                            Cast
                                        </NavLink>
                                    </NavItem>
                                    <NavItem
                                        className={classnames({active: this.state.activeTab === '2'})}
                                    >
                                        <NavLink
                                            className={classnames({active: this.state.activeTab === '2'})}
                                            onClick={(e) => {
                                                this.toggle('2', e);
                                            }}
                                        >
                                            Creative
                                        </NavLink>

                                    </NavItem> 
                                    <NavItem
                                        className={classnames({active: this.state.activeTab === '3'})}
                                    >
                                        <NavLink
                                            className={classnames({active: this.state.activeTab === '3'})}
                                            onClick={(e) => {
                                                this.toggle('3', e);
                                            }}
                                        >
                                            Production
                                        </NavLink>

                                    </NavItem>
                                    <NavItem
                                        className={classnames({active: this.state.activeTab === '4'})}
                                    >
                                        <NavLink
                                            className={classnames({active: this.state.activeTab === '4'})}
                                            onClick={(e) => {
                                                this.toggle('4', e);
                                            }}
                                        >
                                            Orchestra
                                        </NavLink>

                                    </NavItem>
                                    <div
                                        className={`c-collapse-nav nav--scale-bg ${this.state.activeTab === '1' ? 'active' : ''}`}></div>
                                    <div
                                        className={`c-collapse-nav nav--bg ${this.state.activeTab === '2' ? 'active' : ''}`}></div>
                                    <div
                                        className={`c-collapse-nav nav--bg ${this.state.activeTab === '3' ? 'active' : ''}`}></div>
                                    <div
                                        className={`c-collapse-nav nav--bg ${this.state.activeTab === '4' ? 'active' : ''}`}></div>
                                </Nav>
                                <hr/>
                            {/*</ScrollAnimation>*/}
                        </Container>
                    </Container>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1" key={`cast`}>
                            <CustomCollapse members={this.props.data.allNewCastJson.edges} type="cast"/>
                        </TabPane>
                        <TabPane tabId="2" key={`creative`}>
                            <CustomCollapse members={this.props.data.allCreativeJson.edges} type="creative"
                                            title="Creative"/>
                        </TabPane>
                        <TabPane tabId="3" key={`production`}>
                            <CustomCollapse members={this.props.data.allProductionJson.edges} type="production"
                                            title="Production"/>
                        </TabPane>
                        <TabPane tabId="4" key={`orchestra`}>
                            <CustomCollapse members={this.props.data.allOrchestraJson.edges} type="orchestra"
                                            title="Orchestra"/>
                        </TabPane>
                    </TabContent>
                </Content>

                <div className="mt-xl-5">
                    <FindOutMore title="Discover more"/>
                </div>

                <VideoModal toggleModal={this.toggleModal} title={this.state.title} src={this.state.src} modalOpen={this.state.modalOpen}/>

            </Layout>
        )
    }
}

export default Query